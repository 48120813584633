/*
 * FONTS
 */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,700;1,400;1,700&family=Jost:ital,wght@0,300;0,600;1,300;1,600&display=swap');

::-moz-selection { /* Code for Firefox */
  color: #fff;
  background: #44411edd;
}

::selection {
  color: #fff;
  background: #44411edd;
}

/**
 * Reset
 * https://www.joshwcomeau.com/css/custom-css-reset/
 */

*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
	-webkit-tap-highlight-color: #0000;
}

html, body {
  height: 100%;
  font-size:12px;
  background:#000;
}

body {
  -webkit-font-smoothing: antialiased;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}


*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
}

body
{
    font-family: 'Jost', sans-serif;
    font-weight: 300;
    color: #212121;
    font-size: 1.2rem;
    line-height: 1.5;
    overflow-wrap: normal;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

/*typography*/
h1,h2,h3,.serif
{
  font-family: 'Playfair Display', serif;
  font-weight: 400;
  overflow-wrap: normal;
}

h1{
  font-size: 2.4rem;
  margin-bottom: 1.2rem;
  line-height: 3.0rem;
}

h2{
  font-size: 1.8rem;
}

h3{
  margin-top: 1.8rem;
  margin-bottom: 1.8rem;
  font-size: 1.8rem;
  }

p{
  margin-bottom: 1.2rem;
}

strong{
  font-weight:700;
}

/* helpers */
.m-0{
  margin: 0 !important;
}

.vw-100{
  width: 100vw;
}


.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.pointer-on{
  cursor: pointer;
}

/*scene*/
button{

}

#step-bar{
  z-index: 5;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  padding: 1rem;
  font-size: 0;
  display: block;
}

#step-bar button{
  width:1.5rem;
  height:0.5rem;
  margin: 0.5rem 0;
  border-radius: 1rem;
  border: none;
  cursor: pointer;
  border: 1px solid #fff;
  background: #0000;
  transition: all 0.4s;
  position: relative;
}



#step-bar button:hover{
  background: #fff9;
}

#step-bar button.selected{
  background: #fff;
}

/*
#step-bar button:after {
    content: "";
    border-left: 1px solid #fff;
    height: 1rem;
    display: block;
    position: absolute;
    top: calc(1.5rem - 1px);
    left: calc(0.75rem - 1px);
}

#step-bar div:last-child button:after {
    border: 0;
}
*/


.loghi{
  color: #212121;
  background: #fff;
  padding:1rem;
}

.loghi p{
  margin-bottom: 0;
  font-size: 1rem;
}

.loghi .wp-block-gallery{
  display: flex;
  column-gap: 1rem;
  margin-bottom: 2rem;
}

.loghi .wp-block-gallery img{
  height: auto;
  max-height: 5rem;
  width: auto;
}

/*pages*/
#page--section-collection{

}

#page--section-collection .page-content{
	display: flex;
}

#page--section-collection .page-content-element{

}

#page--swipe{
  z-index: 10;
}

#page--swipe.overlayOff{
  min-height: 12rem;
}

#home--page-container{
  padding: 2rem;
  position:absolute;
  top: 0;
  z-index: 10;
  background: #bbb44b55;
}

.fullpage{
	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
}

.text-center {
    padding-bottom: 4rem;
}
@media screen and (min-width: 992px) {
  .text-center {
      padding-bottom: 10rem;
  }
}

.btn{
  font-size: 1.2rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0;
  border: 1px solid #fff;
  margin: 0.5rem;
  font-family: 'Playfair Display';
  cursor: pointer;
  background: #fff;
  transition: background 0.5s;
  color: #212121;
  text-decoration: none;
}

.btn:hover, .btn:active{
  background: #fff9;
}

.btn-back.btn{
  font-size: 0;
  padding: 0;
  width: 3rem;
  height: 3rem;
  border: 1px solid #212121;
  border-radius: 3rem;
  background: #0000;
  position: fixed;
  top: 3rem;
  right: 1rem;
  transform: translate(-50%,-50%);
  margin: 0;
  z-index: 1;
}

.btn-back.btn>span{
  font-size: 0;
  padding: 0;
  width: 2rem;
  border: 0 solid #fff;
  border-top: 3px solid #212121;
  background: #0000;
  display: block;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%) rotate(45deg);
  transform: translate(-50%,-50%) rotate(45deg);
  position: absolute;
}

.btn-back.btn>span:first-child{
  -webkit-transform: translate(-50%,-50%) rotate(135deg);
  transform: translate(-50%,-50%) rotate(135deg);
}

.btn-back.white,
.btn-back.white>span{
  border-color: #fff;
}


#walk-bar{
  padding:0.5rem;
  z-index: 5;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  display: flex;
  flex-direction: column;
}



#walk-bar .btn#btn-indietro{
  /*transform: rotate(180deg) translate(50%,-50%);*/
}

#walk-bar .btn#btn-avanti,
#walk-bar .btn#btn-indietro{
  margin: 0;
  padding: 1rem;
  border-radius: 3rem;
  background: transparent;
}

#walk-bar .btn#btn-avanti:hover,
#walk-bar .btn#btn-avanti:active,
#walk-bar .btn#btn-indietro:hover,
#walk-bar .btn#btn-indietro:active{
  background: #fff9;
}

#walk-bar .btn#btn-avanti{
  margin: 0 0 1rem;
}

#walk-bar .btn#btn-avanti span,
#walk-bar .btn#btn-indietro span{
  width: 1rem;
  height: 1rem;
  transform: translateY(4px) rotate(45deg);
  border: 2px solid #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  display: block;
  font-size: 0;

}

#walk-bar .btn#btn-indietro span{
  transform: translateY(-4px) rotate(-135deg);
}

@media screen and (min-width: 992px) {

  #step-bar{
    right: 2rem;
  }
  #walk-bar{
    right: 1.5rem;
  }
}


.btn.btn-primary{

}

.btn.btn-secondary{
  background: transparent;
  color: #fff;
}

.btn.btn-secondary:hover,
.btn.btn-secondary:active{
  background: #fff5;
}

.btn.btn-ghost{
  background: transparent;
  color: #fff;
  border: 0;
}

.btn.btn-ghost:hover,
.btn.btn-ghost:active{
  color: #fff5;
}

.btn.btn--back{
  position: fixed;
  top:1rem;
  left:1rem;
  width:3rem;
  height: 3rem;
  z-index: 1000;
}

.intro-background{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: block;
  z-index: 1000;
}

.title-box{
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
  z-index: 1001;
  text-align: center;
}

.bg-title{
  display: block;
  width: 80vw;
  height: auto;
  max-width: 1080px;
}

.bg-text{
  color: #fff;
  font-size: 1.5rem;
  margin-bottom:1.2rem;
}



.intro-background.bg-0{
  background: url('assets/img/napoleone-e-l-accademia-tn.jpg') center center;
  background-size: cover;
}

.logo-container{
  position: fixed;
  z-index: 5000;
  top:1rem;
  left:2rem;

}

.logo-container .logo{
  width: 8rem;
  height: auto;
}

.brera-logo-container{
  z-index: 10;
  position: absolute;
  bottom: 1rem;
  width: 10rem;
  left: 2rem;
}


.menu-container{
  position: fixed;
  z-index: 5000;
  top:1rem;
  right:2rem;
}

.menu-container .btn-menu{
  background: none;
      border: 2px solid #fff;
      font-family: 'Playfair Display';
      color: #fff;
      text-shadow: 0 0 10px #000;
      font-size: 1.4rem;
      cursor: pointer;
      padding: 0.5rem 1rem;
}

.page-description-container-margin{
  max-width: 48rem;
  margin: 2rem auto;
}
.page-description-container .page-header{

}
.page-description-container .page-description{
  padding: 2rem;
}


/*menu*/
#menu--page-container{
  padding:2rem;
  position:absolute;
  top:0;
  z-index:10;
  /*background:#44411e;*/
  background:#212121;
  z-index: 12;
  display: flex;
  align-items: center;
}

.intro-text-page{
  overflow: auto;
  padding:2rem;
  position:absolute;
  top:0;
  z-index:10;
  background:#44411edd;
  color: #fff;
}

.intro-text-page a:not(.btn){
  color: #fff;
}

.intro-text-page a:hover, a:focus{
  color: #aaaaaa;
}


.intro-text-page>div{
  max-width: 48rem;
  margin: 5rem auto;
}

.intro-text-page .musica{
  font-size: 1rem;
  border-top: 1px solid #fff;
  padding-top: 2rem;
  margin: 2rem 0 4rem;
}

.intro-text-page h1{
  font-size: 5rem;
  line-height: 1;
  margin-bottom: 5rem;
  font-weight: 400;
}

@media screen and (min-width: 992px) {
  .intro-text-page h1{
    font-size: 6rem;
  }
}

.intro-text-page p{

}

ul, ol{
  margin-left: 1.2rem;
  margin-bottom: 1.2rem;
}

.intro-text-page .musica ul,
.intro-text-page .musica ol{
  margin-left: 1rem;
}

.intro-text-page blockquote{
  font-family: 'Playfair Display';
  margin: 0 4rem 1.5rem;
}

.menu{
  display: block;
  margin: auto;
  max-width: 48rem;
}
.menu li{
  list-style: none;
  margin-bottom: 2rem;
}
.menu li a{
  color: #fff;
  text-decoration: none;
  font-size: 3.6rem;
  line-height: 3.6rem;
  transition: color 0.5s;
}
.menu li a:hover, .menu li a:active{
  color: #44411e;
}


.menu li a.small{
  font-size: 1.5rem;
  line-height: 1.5rem;
}
/*gallery*/
.fullpage.gallery>div{
  display: flex;
}

.fullpage.gallery{
  background: #c9cacd !important;
}

.fullpage.video{
  background: #000000 !important;
}

.fullpage.gallery h1,
.fullpage.model3d h1,
.fullpage.video h1
{
  font-size: 3.2rem;
  font-weight: 400;
  margin-bottom: 2.4rem;
}

.fullpage.gallery{
overflow: auto;
}

.fullpage.gallery>div, .fullpage.model3d>div{
  max-width: 48rem;
  margin: 2rem auto;
}

.fullpage.model3d>div{
  margin: 5rem auto 0;
}

.fullpage.gallery .page-description{
  margin-bottom: 4rem;
}

.fullpage>div .page-header{
  padding: 2rem;
}

.fullpage.gallery>div .page-header{
  /*width: 30vw;
  flex: 1;
  display: flex;
  overflow: auto;
  height: 100vh;*/
}

.pointer{
  cursor: pointer;
}

#page-3d-overlay{
  max-width: none;
  margin: 0;
  width: 100vw;
}

.overlayOff #page-3d-overlay {
}

.fullpage.model3d.overlayOn{
  height: 100vh;
  overflow: auto;
}

.page-description-container{
  flex: 1;
  overflow: auto;
  position: absolute;
  top: 0;
  width: 100vw;
  background: #b5b7bbbb;
}

.overlayOff .page-description-container{
  background: transparent;
}

.overlayOff .page-description-container .page-description-container-margin{
  margin-bottom: 0;
}



/*.model3d .flex .page-header{
  width: 100vw;
  padding: 2rem;
}
@media screen and (min-width: 992px) {
  .model3d .flex .page-header{
    width: 50vw;
  }
}
*/


.mobile .fullpage.gallery{
  overflow: auto;
}

.mobile .fullpage.gallery>div .page-header{
  width: 100vw;
}

.fullpage .video-container{}
.fullpage .video-container video{
  width:70vw;
  height:39.375vw;
  max-width: 1600px;
  max-height: 900px;
  margin: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

/*
@media screen and (min-width: 992px) {
  .fullpage .video-container video{
    width:80vw;
    height:45vw;
  }
}
*/
.pswp__button--close, .pswp__button--zoom{
  width: 3rem !important;
  height: 3rem !important;
  border: 1px solid #fff !important;
  border-radius: 3rem !important;
  margin: 1.5rem 2rem 1.5rem 0 !important;
}

.pswp__button--close .pswp__icn,
.pswp__button--zoom .pswp__icn{
  top: 2px !important;
  left: 2px !important;
}





.pswp-gallery--item figure{
  margin-bottom: 1rem;
}
.pswp-gallery--item{
  margin-bottom: 4rem;
}


.fullpage.gallery>div .page-content-element{
  width: 70vw;
  padding: 0 2rem;
}

.splide__list .splide__slide:first-child figure{

}

.splide__slide figure {
    text-align: center;
}

.splide__slide h3{
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 0.2rem;
  margin-top: 0;
}

.splide__slide p{
  font-size: 1rem;
}

.splide__slide h3,
.splide__slide p{
  opacity: 0;
  transition: opacity 0.5s;
}

.splide__slide.is-active.is-visible h3,
.splide__slide.is-active.is-visible p{
  opacity:1;
}

.splide__slide .splide-info{
  margin-bottom: 2rem;
  text-align: center;
}

.splide__slide a {
    display: inline-block;
    position: relative;
}

.splide__slide a {
    display: inline-block;
    position: relative;
}

.splide__slide a .img-icon{

}

.splide__slide img {
    max-height:70vh;
    margin: auto;
    padding: 1rem 0;
    height: auto;
}

.splide__pagination__page.is-active {
    background: #212121 !important;
}

.splide__pagination__page {
    background: transparent !important;
    border: 1px solid #212121 !important;
}


/* 3d model page */
.fullpage.model3d{
  width: auto;
  height: auto;
}




/*transition*/

.bounce-enter-active{
  animation: fadeIn 1s;
}

.bounce-leave-active {
  animation: fade-out 0.5s;
}

.bounce-enter-from,
.bounce-leave-to{
  opacity: 0;
}

.button-area{
   position: fixed;
   bottom: 0;
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#44411e+0,44411e+99&0+0,1+18 */
  background: -moz-linear-gradient(top,  rgba(68,65,30,0) 0%, rgba(68,65,30,0.8) 18%, rgba(68,65,30,0.8) 99%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(68,65,30,0) 0%,rgba(68,65,30,0.8) 18%,rgba(68,65,30,0.8) 99%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(68,65,30,0) 0%,rgba(68,65,30,0.8) 18%,rgba(68,65,30,0.8) 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0044411e', endColorstr='#44411e',GradientType=0 ); /* IE6-9 */
  left: 0;
  width: calc(100% - 16px);
  padding: 0 2rem;
  z-index: 1;
}

.button-area .button-container{
   max-width: 48rem;
   margin: 3rem auto 1rem;
}

.button-area .button-container .btn{
   max-width: 48rem;
   margin: 0 0 1rem;
}

.intro-text-page .intro-text-page-content{
  Margin-bottom: 10rem;
}


/*transition element*/
#menu--page-container ul,
.intro-text-page h1,
.intro-text-page .intro-text-page-content,
.intro-text-page .btn{
	opacity:0;
	transition: opacity 1s;

	-webkit-animation-duration: 0.5s;
  animation-duration: 0.75s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
	-webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

#menu--page-container ul li{
  opacity:0;
	transition: opacity 1s;
	-webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
	-webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

#menu--page-container ul li:nth-child(1), .intro-text-page h1{
-webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}

#menu--page-container ul li:nth-child(2){
-webkit-animation-delay: 0.30s;
  animation-delay: 0.30s;
}

.intro-text-page .btn{
  -webkit-animation-delay: 0.30s;
  animation-delay: 0.30s;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
}



.intro-text-page .intro-text-page-content{
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

#menu--page-container ul li:nth-child(3), .intro-text-page .intro-text-page-content{
-webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
}

#menu--page-container ul li:nth-child(4){
-webkit-animation-delay: 0.60s;
  animation-delay: 0.60s;
}

#menu--page-container ul li:nth-child(5){
-webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
		opacity: 0;
  }
  50% {
    transform: scale(1.25);
		opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fade-out {
  0% {
		opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes hideCanvas {
  0% {
		opacity: 1;
  }
	80% {
		opacity: 0;
  }
  100% {
    visibility: hidden;
		display: none;
  }
}

@media screen and (min-width: 992px) {
  html{
    font-size:16px;
  }

  .bg-title{
    width: 50vw;
  }
  .loghi .wp-block-gallery img {
    max-height: 8rem;
  }
}

@media screen and (min-width: 1400px) {
  html{
    font-size:18px;
  }
  .bg-text{
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
  }
}

/*loader*/
.box-centered{
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

#scene-loader{
  background: #212121;
  z-index: 10;
  color: #fff;
}

#scene-loader .box-centered{

}


/*help*/

#help-button{
  position: fixed;
  bottom: 1rem;
  z-index: 2;
  left: 1rem;
  padding: 0.5rem;
}

#help-button button{
  font-family: 'Playfair Display', serif;
    width: 3rem;
    height: 3rem;
    color: #fff;
    background: #0000;
    border: 1px solid #fff;
    border-radius: 2rem;
    cursor: pointer;
    transition: background 0.4s;
}

#help-button button:hover,
#help-button button:active{
  background: #fff9;
}

#help-button button.btn-dark{
    color: #212121;
    border: 2px solid #212121;
}

#help-button button.btn-dark:hover,
#help-button button.btn-dark:active{
  background: #21212121;
}


#help--page-container{
  background:#212121f0;
  z-index: 5001;
  overflow-x: auto;
  padding: 0 3rem;
}

.text-center{
  text-align: center;
}

.help-title{
  color:#fff;
  margin-bottom:2rem;
}

.help-box{
  max-width: 48rem;
  margin: 0 auto;
  padding:2rem;
  display:none;
}

.help-box.helpvisible{
  display: block;
}

.help-container{
  display:flex;
  column-gap: 2rem;
  flex-direction: column;
  margin-bottom: 2rem;
}

.help-item{
  text-align: center;
  margin-bottom: 1rem;
}

.help-container .help-item>div{
  width:120px;
  height:120px;
  position: relative;
  margin: 0 auto 1rem;
}

.help-container .help-item p{
  color: #fff;
  line-height: 1.2rem;
  position: relative;
  min-height: 2rem;
}

.help-container .help-item.help-item--1 .help-item--11
{
-webkit-animation: halfhide1 8s infinite;
animation: halfhide1 8s infinite;
}

.help-container .help-item.help-item--1 .help-item--12
{
-webkit-animation: halfhide2 8s infinite;
animation: halfhide2 8s infinite;
}

.help-container .help-item.help-item--1
{
position:relative;
}

.help-container .help-item.help-item--1 .help-item--11,
.help-container .help-item.help-item--1 .help-item--12
{
  position:absolute;
  width: 120px;
  height: 120px;
}

.help-container .help-item.help-item--1>div,
.help-container .help-item.help-item--1>div
{
height: 120px;
}

.help-container .help-item.help-item--1 p span{
  display: block;
  text-align: center;
  width: 100%;
}

.help-container .help-item.help-item--1 .help-item--11 img{
  position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: helphand 2s infinite;
    animation: helphand 2s infinite;
    transform-origin: center left;
    transition: transform 0.5s;
    z-index: 1;
}

.help-container .help-item.help-item--1 .help-item--11 .h-point{
  width: 25px;
  height: 25px;
  background: #0000;
  border: 1px solid #fff;
  position: absolute;
  border-radius: 25px;
  top: 25px;
  left: 65px;
  -webkit-animation: helphandtouch 2s infinite;
  animation: helphandtouch 2s infinite;
  transition: transform 0.5s;
}

.help-container .help-item.help-item--1 .help-item--12 img{
  position: absolute;
  top: 0;
  left: 0;
}

.help-container .help-item.help-item--1 .help-item--12 img.hand{
  z-index:1;
  -webkit-animation: mousehand 2s infinite;
  animation: mousehand 2s infinite;
  transition: transform 0.5s;
  animation-delay: -2s;
}

.help-container .help-item.help-item--3 img{
  position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: touchhand 5s infinite;
    animation: touchhand 5s infinite;
    transform-origin: center left;
    transition: transform 0.5s;
    z-index: 1;
}

.help-container .help-item.help-item--3 .h-points{
  width: 25px;
  height: 25px;
  background: #0000;
  border: 1px solid #fff;
  position: absolute;
  border-radius: 25px;
  left: 70px;
}

.help-container .help-item.help-item--3 .h-points:nth-child(2){
  top: 15px;
  animation: touchhandpoint1 5s infinite;
}

.help-container .help-item.help-item--3 .h-points:nth-child(3){
  top: 50px;
  animation: touchhandpoint2 5s infinite;
}

.help-container .help-item.help-item--3 .h-points:nth-child(4){
  top: 85px;
  animation: touchhandpoint3 5s infinite;
}

.help-container .help-item.help-item--4 img{
  position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: doubletap 2s infinite;
    animation: doubletap 2s infinite;
    transform-origin: center left;
    z-index: 1;
}

.help-container .help-item.help-item--4 .h-square{
  border: 1px solid #fff;
  width: 50px;
  height: 70px;
  display: block;
  position: absolute;
  top: 20px;
  left: 43px;
  -webkit-animation: doubletapsquare 2s infinite;
  animation: doubletapsquare 2s infinite;
}

.help-container .help-item.help-item--6 .img-mobile-1,
.help-container .help-item.help-item--6 .img-mobile-2,
.help-container .help-item.help-item--6 .img-mobile-3
{
  z-index:1;
  position: absolute;
}

.help-container .help-item.help-item--6 .img-mobile-1{
  -webkit-animation: hide1 6s infinite;
  animation: hide1 6s infinite;
}

.help-container .help-item.help-item--6 .img-mobile-2{
  -webkit-animation: hide2 6s infinite;
  animation: hide2 6s infinite;
}

.help-container .help-item.help-item--6 .img-mobile-3{
  transform: translateY(15px) rotate(-35deg);
  -webkit-animation: hide3 6s infinite;
  animation: hide3 6s infinite;
}

.help-container .help-item.help-item--6 .h-square-mobile{
  border: 1px solid #fff;
  width: 50px;
  height: 80px;
  display: block;
  position: absolute;
  top: 15px;
  left: 65px;
  border-radius: 4px;
  transform: rotate(-5deg);
}

.help-container .help-item.help-item--7 .h-point-mouse{
  width: 15px;
  height: 15px;
  background: #fff;
  position: absolute;
  border-radius: 25px;
}

.help-container .help-item.help-item--7 .h-point-mouse:nth-child(2){
  top: 30px;
  left: 53px;
  -webkit-animation: hidescale1 6s infinite;
  animation: hidescale1 6s infinite;
}

.help-container .help-item.help-item--7 .h-point-mouse:nth-child(3){
  top: 43px;
  left: 37px;
  -webkit-animation: hidescale2 6s infinite;
  animation: hidescale2 6s infinite;
}

.help-container .help-item.help-item--7 .h-point-mouse:nth-child(4){
  top: 43px;
  left: 68px;
  -webkit-animation: hidescale3 6s infinite;
  animation: hidescale3 6s infinite;
}

/*.help-container .help-item.help-item--6 p span:nth-child(1),
.help-container .help-item.help-item--7 p span:nth-child(1){
  -webkit-animation: hide1 10s infinite;
  animation: hide1 10s infinite;
}

.help-container .help-item.help-item--6 p span:nth-child(2),
.help-container .help-item.help-item--7 p span:nth-child(2){
  -webkit-animation: hide2 10s infinite;
  animation: hide2 10s infinite;
}

.help-container .help-item.help-item--6 p span:nth-child(3),
.help-container .help-item.help-item--7 p span:nth-child(3){
  -webkit-animation: hide3 10s infinite;
  animation: hide3 10s infinite;
}*/

.help-container .help-item.help-item--6 p,
.help-container .help-item.help-item--7 p{
  position: relative;
  display: block;
  min-height:2rem;
}

.help-container .help-item.help-item--6 p span,
.help-container .help-item.help-item--7 p span  {
  /* position: absolute;
  left: 50%;
  transform: translate(-50%,0); */
  display: block;
  width: 100%;
  text-align: center;
}

.help-container .help-item.help-item--1,
.help-container .help-item.help-item--3,
.help-container .help-item.help-item--4,
.help-container .help-item.help-item--6,
.help-container .help-item.help-item--7{
  width: 100%;
}



@media screen and (min-width: 992px) {
  .help-box{
    transform: translate(-50%,-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
  }
  .help-container{
    flex-direction: row;
  }
  .help-item{
    text-align: left;
  }
  .help-container .help-item.help-item--1 p span{
    text-align: left;
  }
  .help-container .help-item p{
  }
  .help-container .help-item.help-item--6,
  .help-container .help-item.help-item--7{
    width: 50%;
  }
  .help-container .help-item.help-item--1,
  .help-container .help-item.help-item--3,
  .help-container .help-item.help-item--4
  {
    width: 33%;
  }
}

@keyframes halfhide1 {
  from {
		opacity: 0;
  }
  3% {
    opacity: 1;
  }
  47% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

@keyframes halfhide2 {
  from {
		opacity: 0;
  }
  50% {
    opacity: 0;
  }
  53% {
    opacity: 1;
  }
  97% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes hidescale1 {
  from {
		opacity: 0;
    transform: scale(0);
  }
  3% {
    opacity: 1;
    transform: scale(1);
  }
  30% {
    opacity: 1;
    transform: scale(1);
  }
  33% {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}
@keyframes hidescale2 {
  from {
		opacity: 0;
    transform: scale(0);
  }
  30% {
    opacity: 0;
    transform: scale(0);
  }
  33% {
    opacity: 1;
    transform: scale(1);
  }
  63% {
    opacity: 1;
    transform: scale(1);
  }
  66% {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}
@keyframes hidescale3 {
  from {
		opacity: 0;
    transform: scale(0);
  }
  60% {
    opacity: 0;
    transform: scale(0);
  }
  66% {
    opacity: 1;
    transform: scale(1);
  }
  97% {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes hide1 {
  from {
		opacity: 0
  }
  3% {
    opacity: 1
  }
  30% {
    opacity: 1
  }
  33% {
    opacity: 0
  }
  to {
    opacity: 0
  }
}
@keyframes hide2 {
  from {
		opacity: 0
  }
  30% {
    opacity: 0
  }
  33% {
    opacity: 1
  }
  63% {
    opacity: 1
  }
  66% {
    opacity: 0
  }
  to {
    opacity: 0
  }
}
@keyframes hide3 {
  from {
		opacity: 0
  }
  60% {
    opacity: 0
  }
  66% {
    opacity: 1
  }
  97% {
    opacity: 1
  }
  to {
    opacity: 0
  }
}

@keyframes doubletapsquare {
  from {
		background: transparent;
    transform: scale(1);
  }
  45% {
    background: transparent;
  }
  50% {
    background: #fff;
    transform: scale(1);
  }
  60% {
    transform: scale(1.8);
  }
  to {
    background: transparent;
    transform: scale(1);
  }
}

@keyframes doubletap {
  from {
		transform: rotate(0);
  }
  20% {
    transform: rotate(0);
  }
  30% {
    transform: rotate(15deg);
  }
  40% {
    	transform: rotate(0);
  }
  50% {
    	transform: rotate(15deg);
  }
  65% {
    	transform: rotate(0);
  }
  to {
  	transform: rotate(0);
  }
}

@keyframes touchhandpoint1 {
  from {
		background: transparent;
  }
  5% {
    background: #fff;
  }
  15% {
    background: transparent;
  }
  to {
    background: transparent;
  }
}

@keyframes touchhandpoint2 {
  from {
		background: transparent;
  }
  70% {
    background: transparent;
  }
  75% {
    background: #fff;
  }
  85% {
    background: transparent;
  }
  to {
    background: transparent;
  }
}
@keyframes touchhandpoint3 {
  from {
		background: transparent;
  }
  45% {
    background: transparent;
  }
  50% {
    background: #fff;
  }
  60% {
    background: transparent;
  }
  to {
    background: transparent;
  }
}

@keyframes touchhand {
  from {
		transform: scale(1) rotate(0);
  }
  50% {
    transform: scale(1) rotate(45deg);
  }
  75% {
    transform: scale(1) rotate(20deg);
  }
  to {
  transform: scale(1) rotate(0);
  }
}

@keyframes helphand {
  from {
		transform: scale(1.3) rotate(0) translateY(0);
  }
	10% {
		transform: scale(1) rotate(0) translateY(0);
  }
  60% {
    transform: scale(1) rotate(0) translateY(60px);
  }
  80% {
    transform: scale(1) rotate(0) translateY(60px);
  }
  to {
  transform: scale(1.3) rotate(0) translateY(0);
  }
}

@keyframes helphandtouch {
  from {
    transform: scale(0) translate(0,0);
  }
  5% {
    transform: scale(0) translate(0,0);
  }
	10% {
    transform: scale(1) translate(0,0);
  }
  80% {
    transform: scale(1) translate(0, 60px);
  }
  to {
    transform: scale(0) translate(0, 60px);
  }
}

@keyframes mousehand {
  from {
		transform: translate(0,0);
  }
	50% {
		transform: translate(10px,-10px);
  }
  to {
  transform: translate(0,0);
  }
}
